import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

  *{
    box-sizing: border-box;
  }

  html{
    background-color: #020833;
  }

`