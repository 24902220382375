import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 700px;
  max-height: 240px;
  margin: 0 auto;

  .topo{
    margin: 15px;
    display: flex;
    a{
      text-decoration: none;
      color: white;
      font-weight: bold;
      font-size: 20px;
      background-color: brown;
      padding: 10px;
    }
  }

  li{
    border-radius: 20px;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: lightgray;

    margin: 10px 0;
    padding: 8px 15px;

    div.image{

      width: 50%;

      iframe{
        border: 5px solid black;
        width: 250px;
      }
    }

    span{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .info{
        text-align: center;
      }

      p{
        margin: 0;
        font-size: 18px;
      }
      .title{
        margin: -5px 5;
        font-size: 20px;
        color: black;
        font-weight: bold;
      }
      h2{
        margin: 0;
      }

      .id{
        width: 300px;
        margin-top: 5px;
        text-align: left;
      }
      .disp{
        background-color: brown;
        color: white;
        font-weight: bold;
        padding: 3px;
        display: inline-block;
      }
      .yes{
        background-color: #027322;
        color: white;
        font-weight: bold;
        padding: 3px;
        display: inline-block;
      }
      .no{
        background-color: brown;
        color: white;
        font-weight: bold;
        padding: 3px;
        display: inline-block;
      }

    }

    .btn-rm{
      button{
        font-size: 20px;
        padding: 5px;
        margin: 5px;
      }
    }
  }
`;
