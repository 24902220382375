import { useEffect, useState } from "react";
import { Styles } from "./globalStyles";
import db from "./server/firebaseConnect";
import { Container } from "./styles";

function App() {

  const [carro, setCarro] = useState([])
  const [carId, setCarId] = useState('')

  useEffect(()=>{

    async function loadCar(){
      await db.collection('carros').onSnapshot((doc)=>{

        let carro = []

        doc.forEach((i)=>{
          carro.push({
            model: i.data().model,
            brand: i.data().brand,
            price: i.data().price,
            disp: i.data().disp,
            image: i.data().i360,
            desc: i.data().desc,
            id: i.id
          })
        })
        setCarro(carro)
      })
    }

    loadCar()
  }, [])

  async function dispEditTrue(){
    await db.collection('carros').doc(carId)
    .update({
      disp: true
    }).then(()=>{
      setCarId('')
    })
    .catch(()=>{
      alert('deu errado')
      setCarId('')
    })
  }
  async function dispEditFalse(){
    await db.collection('carros').doc(carId)
    .update({
      disp: false
    }).then(()=>{
      setCarId('')
    })
    .catch(()=>{
      alert('deu errado')
      setCarId('')
    })
  }

  async function deleteCar(){
    await db.collection('carros').doc(carId).delete()
  }

  return (
    <Container>
    <div className='topo'>
      <a href="https://www.console.add.ellacarautos.com.br/">Adicionar um novo carro</a>
    </div>
      {
        carro.map((item) => {
          return(
            <li key={item.id}>

                <div className='image'>
                <iframe src={item.image}/>
                </div>
              <span>

                <div className='info'>
                  <p className='title'>{`${item.brand} ${item.model}`}</p>
                  <h2>{item.price}</h2>

                  <p className={item.disp === true ? 'yes' : 'no'}>{item.disp === true ? 'disponivel' : 'Vendido'}</p>
                  <p className='id'>id - {item.id}</p>
                </div>

                <div className='btn-rm'>
                  {
                    item.disp === true 
                    ?
                    <button onClick={() => {
                      setCarId(item.id)
                      if(carId !== ''){
                        if(carId !== item.id){
                          alert("os id's não correspondem!")
                          setCarId('')
                        }else{
                        dispEditFalse()
                        }
                      }else{
                        alert(`
                        Id Gegistrado!
                    
                        clique novamente!
                        `)
                      }
                    }}>
                      Marcar c/Vendido
                    </button>
                    :
                    <button onClick={()=>{
                      setCarId(item.id)
                      if(carId !== ''){
                        if(carId !== item.id){
                          alert("os id's não correspondem!")
                          setCarId('')
                        }else{
                        dispEditTrue()
                        }
                      }
                      else{
                        alert(`
                        Id Gegistrado!
                    
                        clique novamente!
                        `)
                      }
                    }}>
                      Deixar disponivel
                    </button>
                    
                  }
                  <button onClick={() => {
                      setCarId(item.id)
                      if(carId !== ''){
                        if(carId !== item.id){
                          alert("os id's não correspondem!")
                          setCarId('')
                        }else{
                        deleteCar()
                        setCarId('')
                        alert('anuncio excluido com sucesso!')
                        }
                      }else{
                        alert(`
                        Id Gegistrado!
                    
                        clique novamente!
                        `)
                      }
                    }}>[excluir anuncio]</button>
                  <p>[o 1º clique registra o id, então precisa clicar 2 vezes!]</p>

                </div>

              </span>
            </li>
          )
        })
      }
      <Styles/>
    </Container>
  )
}

export default App;
